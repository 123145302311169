@import "https://fonts.googleapis.com/css2?family=Parisienne&display=swap";
@import "https://fonts.cdnfonts.com/css/dinpro-medium";
@import "https://fonts.cdnfonts.com/css/dinpro-regular";
.grid-badge {
  border: 1px solid;
  font-weight: normal;
}

.grid-badge.info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.grid-badge.warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffe69c;
}

.grid-badge.danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.grid-badge.success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
/*# sourceMappingURL=workspace.f5bc2289.css.map */
